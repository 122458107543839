.mining-pool-detail{
    width: 100%;
    padding: 64px 0;
    background: #f9f9f9;
    min-height: 100vh;
    .container{
        padding: 0 35px;
        @media (max-width: 767px) {
            padding: 50px 30px;
        }
    }
    &>div{
        max-width: 1200px;
        margin: 0 auto;
        &:first-of-type{
            width: 100%;
            max-width: 100%;
            margin: 0 auto 20px;
        }
    }
    .content{
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 16px 26px;
        background-color: #fff;
        @media (max-width: 767px) {
            padding: 15px;
        }
    }
    .back{
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: #000;
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        &>div{
            &:first-of-type{
                cursor: pointer;
            }
        }
        svg{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    .join{
        background: #40BA5B;
        border-radius: 0px 0px 6px 6px;
        text-align: center;
        color: #F5FFB5;
        font-size: 14px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            margin-right: 5px;
        }
        span{
            font-weight: 700;
            color: #fff;
        }
    }
    
    .descriptions{
        display: grid;
        padding: 16px 26px;
        grid-template-columns:60px repeat(7, auto);
        grid-gap: 10px;
        @media (max-width: 767px) {
            grid-template-columns: 45px repeat(2, auto)!important;
            grid-template-rows: repeat(3, auto);
            grid-gap: 10px;
        }
        &.open{
            grid-template-columns:60px repeat(5, auto) 100px;
        }
        &.locked{
            grid-template-columns:60px repeat(5, auto);
        }
        &.mining{
            grid-template-columns:60px repeat(5, auto);
        }
        .btn-box{
            justify-content: center;
            align-items: flex-end;
        }
        .status{
            margin-left: 10px;
        }
        &>div{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 50px;
            @media (max-width: 767px) {
                &.status{
                    grid-column-start: 3;
                    grid-column-end: 4;
                    grid-row-start: 1;
                    grid-row-end: 2;
                    text-align: right;
                    div{
                        &:last-of-type{
                            justify-content: flex-end;
                        }
                    } 
                }
                &:nth-of-type(3){
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                &:nth-of-type(5){
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 3;
                    grid-row-end: 4;
                }
            }
            &:first-of-type{
                width: 60px;
                justify-content: center;
            }
            img{
                width: 45px;
            }
            .fil{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            div{
                align-items: center;
                &:first-of-type{
                   color: #9A9A9A;
                   height: 20px;
                }
                &:last-of-type{
                    color: #000;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 1.1;
                }
            }
            .ant-badge{
                &-status-dot{
                    height: 10px;
                    width: 10px;
                }
            }
            .progress{
                min-width: 80px;
            }
            .ant-progress{
                position: relative;
                width: 100%;
                &-inner{
                    background: #F4F4F4;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 60px;
                    height: 20px;
                }
                &-outer{
                    padding-right: 0;
                }
                &-bg{
                    background: linear-gradient(180deg, #40BA5B 0%, #9FE47E 100%);
                    border-radius: 60px;
                    height: 18px!important;
                }
                &-text{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    color:rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 20px;
                    margin-left: 0;
                }
                &-status-success{
                    .ant-progress-bg{
                        background: linear-gradient(0deg, #FFCC68 0%, #FF8E26 100%);
                    }
                }
            } 
        }
        .ant-btn{
            height: 35px;
            width: 100px;
            padding: 0;
            line-height: 35px;
            border:none;
            background: #40BA5B;
            color: #fff;
            &[disabled]{
                background: #d9d9d9!important;
            }
        }
        
    }
    .data{
        margin-top: 20px;
        .content{
            position: relative;
            &::before{
                @media (max-width: 767px) {
                    display: none;
                }
                position: absolute;
                left: 50%;
                content: '';
                height: calc(100% - 32px);
                width: 1px;
                background: #DEDEDE;
            }
        }
        .title{
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #000;
            position: relative;
            padding-left: 30px;
            margin-bottom: 20px;
            svg{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 24px;
                padding-right: 10px;
            }
        }
        .first{
            display: flex;
            justify-content: space-between;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            &>div{
                background: #F9F9F9;
                padding: 15px;
                display: flex;
                width: calc(50% - 20px);
                justify-content: space-between;
                align-items: center;
                line-height: 1.1;
                font-size: 18px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin: 5px 0;
                }
                div{
                    &:first-of-type{
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    &:last-of-type{
                        font-size: 32px; 
                        font-weight: bold; 
                        @media (max-width: 767px) {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .second{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            &>div{
                display: flex;
                width: calc(50% - 20px);
                justify-content: space-between;
                align-items: center;
                @media (max-width: 767px) {
                    width: 100%;
                }
                div{
                    &:first-of-type{
                       color: #9A9A9A;
                    }
                    &:last-of-type{
                        color: #000;
                        font-size: 18px;
                        line-height: 1.1;
                    }
                }
            }
        }
    }
    .account-info{
        margin-top: 20px;
        .title{
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #000;
            position: relative;
            padding-left: 30px;
            margin-bottom: 20px;
            svg{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        .info{
            display: grid;
            grid-template-columns: repeat(4, minmax(80px, 1fr));
            @media (max-width: 767px) {
                grid-template-columns: repeat(2, minmax(80px, 1fr));
                grid-template-rows: repeat(2, 1fr);
            }
            div{
                div{
                    &:first-of-type{
                       color: #9A9A9A;
                       height: 20px;
                       margin-bottom: 10px;
                    }
                    &:last-of-type{
                        color: #000;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .change{
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr; 
        grid-gap: 20px;
        width: 100%;
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr); 

        }
        .power{
            width: 100%;
            .content{
                padding: 10px;
                width: 100%;
                .info{
                    display: grid;
                    grid-template-columns: repeat(2, 50%); 
                    &>div{
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                        div{
                            &:first-of-type{
                                font-size: 14px;
                                color: #9A9A9A;
                            }
                            &:last-of-type{
                                font-size: 16px; 
                            }
                        }
                    }
                }

                .chart{
                    height: 200px;
                    width: 100%;
                }
            }
        }
        .earning{
            width: 100%;
            .content{
                padding: 10px 20px;
                width: 100%;
                .chart{
                    height: 200px;
                    width: 100%;
                }
            }
        }
        .nodata{
            background: #F4F4F4;
            border-radius: 6px;
            width: 100%;
            height: 210px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #9A9A9A;
        }
        .title{
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #000;
            position: relative;
            padding-left: 30px;
            margin-bottom: 20px;
            svg{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .tipsets{
        margin-top: 20px;
        .title{
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #000;
            position: relative;
            margin-bottom: 20px;
            svg{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        } 
        .ant-table{
            border-radius: 6px;
            .ant-table-container table > thead > tr:first-child th:first-child{
                border-top-left-radius: 6px;
            }
            .ant-table-container table > thead > tr:first-child th:last-child{
                border-top-right-radius: 6px;
            }
        }
    }
    .ant-radio-group{
        border: 1px solid #DEDEDE;
        border-radius: 20px;
        .ant-radio-button-wrapper{
            height: 38px;
            line-height: 38px;
            border-color: #DEDEDE!important;
            background: #EDEDED;
            border-radius: 20px!important;
            padding: 0 45px;
            border: none;
            &:hover{
                color: #000;
            }
            &-checked{
                background: #40BA5B;
                color: #fff;
                outline: none;
                &:not(.ant-radio-button-wrapper-disabled)::before{
                    display: none;
                }
                &:not(.ant-radio-button-wrapper-disabled):focus-within{
                    box-shadow: none;
                }
                &:hover{
                    color: #fff;
                }
            }
            &:not(:first-child)::before{
                display: none;
            }
            &:not(:first-of-type){
                margin-left: -34px;
            }
        }
    }
}
.ant-modal{
    &-header{
        border-radius: 6px 6px 0 0;  
        padding: 16px 40px;
    }
    &-content{
        border-radius: 6px;
    }
    &-body{
        padding: 16px 40px;
    }
    &-footer{
        border-radius: 0 0 6px 6px;
    }
}
.minerModal{
    .ant-modal-header,.ant-modal-body{
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
    .ant-modal-title{
        font-weight: 700;
        font-size: 18px;
    }
    .qraddress{
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 30px;
        &>div{
            width: 100%;
            &:first-of-type{
                @media (max-width: 767px) {
                    margin-bottom: 10px;
                }
            }
            div{
                &:first-of-type{
                    color: #9A9A9A;
                    font-size: 14px;
                    margin-bottom: 7px;
                }
            }
            .address{
                font-weight: 600;
                font-size: 16px;
                color: #000; 
                word-break: break-all;
                max-width: 180px;
            }
            .amount{
                font-weight: 600;
                font-size: 24px;
                color: #000;
            }
            .qrcode{
               display: flex;
               justify-content: space-between;
               margin: 10px 0;
               font-size: 14px;
               align-items: center;
               width: 100%;
                &>div {
                    &:nth-of-type(2){
                        word-break: break-all;
                        margin: 0 20px;
                    }
               }
               .copyIcon{
                   text-align: right;
                   cursor: pointer;
                   width: 64px;
                   padding: 0 5px;
                   margin-top: 10px;
                   text-align: center;
                   border: 1px solid #000;
                   border-radius: 2px;
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   color: #000;
               }
            }
        }
    }
    .ant-form{
        margin: 20px 0;
        .ant-form-item-label > label{
            font-weight: 400;
            font-size: 14px;
            color: #9A9A9A;
        }
        .ant-input-number-affix-wrapper{
            border-radius: 4px;
            height: 36px;
            line-height: 36px;
            width: 100%;
            padding-left: 0;
            flex-direction: row-reverse;
            .ant-input-number{
                line-height: 36px;
            }
            .ant-input-number-input{
                height: 36px;
                line-height: 36px;
                padding-left: 10px;
            }
            .ant-input-number-prefix{
                margin: 0 4px;
            }
        }
        .ant-btn-primary{
            background: #40BA5B;
            border:none;
        }
    }
    .help{
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        color: #000;
        a{
            color: #000;
            font-weight: bold;
        }
    }
    .check{
        text-align: center;
        margin: 10px 0;
        font-weight: 400;
        font-size: 12px;
        color: #000;

    }
    h3{
        color: #000;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
    ol{
        padding: 0 30px;
        li{
            font-size: 12px;
            font-weight: 500;
        }
    }
    ul{
        background: #F7F7F7;
        border-radius: 4px;
        padding: 15px;
        padding-left: 30px;
        margin-top: 20px;
        margin-bottom: 0;
    }
    p{
        background: #F7F7F7;
        border-radius: 4px;
        padding: 15px;
    }
    .ant-modal-body{
        min-height: 400px;
    }
    .content{
        position: relative;
        padding: 20px 0 30px;
        margin-bottom: 20px;
    }
    .instructions{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        padding: 30px;
        background: rgba(255, 237, 191, 0.63);
        backdrop-filter: blur(23px);
        border-radius: 6px;
        @media (max-width: 767px) {
            padding: 20px;
        }
        .ant-btn{
            height: 35px;
            margin: 0 auto;
            display: block;
            padding: 0 20px;
            line-height: 35px;
            border:none;
            background: #40BA5B;
            color: #fff;
            &[disabled]{
                background: #d9d9d9!important;
            }
        }
    }
}

@primary-color: #000;@border-radius-base: 20px;