.miner-detail{
    width: 100%;
    padding: 64px 0;
    background: #f9f9f9;
    min-height: 100vh;
    .container{
        padding: 0 35px;
        @media (max-width: 767px) {
            padding: 50px 30px;
        }
    }
    &>div{
        max-width: 1200px;
        margin: 0 auto;
        &:first-of-type{
            width: 100%;
            max-width: 100%;
            margin: 0 auto 20px;
        }
    }
    .content{
        background-color: #fff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 16px 26px;
    }
    .back{
        font-size: 18px;
        line-height: 30px;
        color: #000;
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        &>div{
            &:first-of-type{
                font-weight: bold;
                cursor: pointer;
                svg{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &:last-of-type{
                cursor: pointer;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .title{
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #000;
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        svg{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    .account-detail-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .title{
            margin-bottom: 0;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 0;
            div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            svg{
                position: relative;
                top: 0;
                transform: translateY(0);
                margin-right: 10px;
            }
            p{
                margin: 0;
                padding-top: 10px;
                font-weight: normal;
                font-size: 14px;
            }
            a{
                color: #000!important;
            }
        }
        .ant-select{
            margin-left: 20px;
            width: 200px;
            text-align: left;
            @media (max-width: 767px) {
                margin-left: 0;
                margin-top: 10px;
            }
            .anticon-caret-down{
                float: right;
            }
        }
    }
    .descriptions{
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        .content{
            display: grid;
            grid-template-columns: 30% 1fr 1fr 1fr 200px;
            background: linear-gradient(269.66deg, #000000 0%, #308B44 99.65%);
            @media (max-width: 767px) {
                grid-template-columns: 100%;
                grid-template-rows: repeat(5, 1fr);
            }
            &>div{
                &:first-of-type{
                    div{
                        &:last-of-type{
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
                &:not(:first-of-type){
                    padding-left: 20px;
                    @media (max-width: 767px) {
                        padding-left: 0;
                    }
                }
                &:last-of-type{
                    text-align: right;
                    cursor: pointer;
                    justify-self: center;
                    align-items: center;
                    display: flex;
                    @media (max-width: 767px) {
                        text-align: left;
                    }
                    svg{
                        height: 28px;
                        width: auto;
                        margin-right: 10px;
                    }
                    .ant-btn{
                        border: 1px solid #fff;
                        background: none;
                        height: 41px;
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
                div{
                    color:#fff;
                    &:first-of-type{
                        font-size: 14px; 
                    }
                    &:last-of-type{
                        font-size: 20px; 
                    }
                }
            }
        }
    }
    .miner-info{
        margin-bottom: 20px;
        cursor: pointer;
    }
    .maintenance{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
        background: #F4F4F4;
        border-radius: 6px;
        svg{
            width: 45px;
            height: 45px;
            margin-bottom: 20px;
        }
        p{
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #9A9A9A;
            width: 280px;
            text-align: center;
        }
    }
    .warning{
        background: #E76F00;
        border-radius: 6px;
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg{
            width: 18px;
            height: 18px;
            margin: 0 16px;
        }
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #fff;
            margin-bottom: 0;
        }
    }
    .miner-data{
        position: relative;
    }
    .mining-data{
        display: grid;
        grid-gap: 20px;
        @media (max-width: 767px) {
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        }
        &.detial{
            grid-template-columns: repeat(5, auto);
        }
        &.main{
            grid-template-columns: repeat(5, auto) 50px;
            &>div{
                &:last-of-type{
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
        &>div{
            img{
                width: 45px;
            }
            &>div{
                &:first-of-type{
                   color: #9A9A9A;
                }
                &:last-of-type{
                    color: #000;
                    font-size: 18px;
                    height: 32px;
                    line-height: 32px;
                    span{
                        font-size: 14px;
                        font-weight: normal; 
                    }
                }
            }
            &.pending{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .data-content{
        margin: 20px auto 40px;
        overflow: hidden;
        .content{
            display: grid;
            grid-template-columns: 1.4fr 1fr;
            grid-gap: 50px;
            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-gap: 20px;
            }
        }
        .first{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-row-gap: 10px;
            &>div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background: #F9F9F9;
                height: 80px;
                div{
                    padding: 10px;
                    &:first-of-type{
                        font-size: 14px; 
                        color: #9A9A9A;
                    }
                    &:last-of-type{
                        font-size: 32px;
                        font-weight: bold; 
                        @media (max-width: 767px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .second{
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            align-items: center;
            &>div{
                div{
                    &:first-of-type{
                        color: #9A9A9A;
                        font-size: 14px;
                    }
                    &:nth-of-type(2){
                        color: #000;
                        font-size: 18px;
                        line-height: 35px;
                    }
                    &:last-of-type{
                        font-size: 18px; 
                        color: #000;
                    }
                }
            }
        }
        .withdraw{
            position: relative;
            padding:0 26px;
            background-color: #fff;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
            &::before{
                position: absolute;
                content: '';
                top: -10px;
                left: -10px;
                height: 20px;
                width: 20px;
                background: #f9f9f9;
                border-radius: 100%;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
            }
            &::after{
                position: absolute;
                content: '';
                top: -10px;
                right: -10px;
                height: 20px;
                width: 20px;
                background: #f9f9f9;
                border-radius: 100%;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
            }
            .content{
                padding: 36px 26px;
                box-shadow: none;
                border-top: 1px dashed #B0B0B0;
                border-radius: 0;
                &>div{
                    &:first-of-type{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        div{
                            &:first-of-type{
                                color: #9A9A9A;
                                font-size: 14px;
                                padding-left: 10px;
                            }
                            &:nth-of-type(2){
                                color: #000;
                                font-size: 32px;
                                font-weight: bold; 
                                padding-right: 10px;
                                @media (max-width: 767px) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                    &:last-of-type{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: flex-end;
                        p{
                            margin-bottom: 0;
                            width: 160px;
                            text-align: center;
                        }
                        .ant-btn{
                            width: 160px;
                            height: 40px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
        .attention{
            background: #40BA5B;
            border-radius: 0px 0px 6px 6px;
            color: #fff;
            text-align: center;
            padding: 10px;
            ul{
                padding-left: 15px;
            }
        }
    }
    .change{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
        }
        // .recharts-surface{
        //     padding-bottom: 20px;
        // }
        .power{
            .content{
                padding: 10px;
                .chart{
                    height: 210px;
                }
            }
        }
        .earning{
            .content{
                padding: 10px;
                .chart{
                    height: 210px;
                }
            }
        }
        .nodata{
            background: #F4F4F4;
            border-radius: 6px;
            width: 100%;
            height: 210px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #9A9A9A;
        }
    }
    .tipsets{
        margin-top: 20px;
        .title{
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            color: #000;
            position: relative;
            margin-bottom: 20px;
            svg{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        } 
    }
    .pending-data{
        .title{
            padding-left: 0;
        }
        .ant-steps-horizontal{
            position: relative;
            width: 80%;
            margin: 20px 10%;
            &::after{
                position: absolute;
                top: 20px;
                left: 7.5%;
                display: block;
                width: 30%;
                height: 2px;
                background: #E2E2E2;
                content: '';
                z-index: 1;
            }
            &::before{
                position: absolute;
                top: 20px;
                left: 37.5%;
                display: block;
                width: 50%;
                height: 0;
                border-bottom:2px dotted #E2E2E2;
                content: '';
                z-index: 1;
            }
            @media (max-width: 767px) {
                width: 100%;
                margin: 20% 0;
                &::after{
                    display: none;
                }
                &::before{
                    display: none;
                }
            }
            .ant-steps-item{
                overflow: auto;
                padding-left: 0;
                .ant-steps-item-container{
                    text-align: center;
                    @media (max-width: 767px) {
                        text-align: left;
                    }
                }
                &:first-of-type,&:nth-of-type(2),&:nth-of-type(3){
                    width: 15%;
                    @media (max-width: 767px) {
                        width: 100%;
                        height: 80px;
                    }
                }
                &:last-of-type{
                    width: 55%;
                    position: relative;
                    &::before{
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        content: ''; 
                        left: 77%;
                        top: 12px;
                        border-top: 10px solid transparent;
                        border-left: 10px solid #E2E2E2;
                        border-bottom: 10px solid transparent;
                    }
                    .ant-steps-item-icon{
                        padding-right: 35px;
                        &::after{
                            display: none;
                        }
                    }
                    .ant-steps-item-container{
                        text-align: right;
                    }
                    .ant-steps-item-title{
                        text-align: right;
                    }
                }
                .ant-steps-item-title{
                    padding: 0;
                    line-height: 1.2;
                    text-align: center;
                }
            }
            .ant-steps-item-icon{
                margin: 0 auto;
                z-index: 2;
                position: relative;
            }
            .ant-steps-item-process{
                .ant-steps-item-icon{
                    background: #E2E2E2;
                    border-radius: 50%;
                    height: 42px;
                    width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .ant-steps-icon{
                        line-height: 1;
                        width: auto;
                        height: auto;
                        font-size: inherit;
                    }
                    svg{
                        fill: #000;
                    }
               } 
            }
            .ant-steps-item-finish{
                .ant-steps-item-icon{
                    background: #40BA5B;
                    border-radius: 50%;
                    height: 42px;
                    width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .ant-steps-icon{
                        line-height: 1;
                        width: auto;
                        height: auto;
                        font-size: inherit;
                    }
                    svg{
                        fill: #fff;
                    }
               } 
            }
            .ant-steps-item-title{
                &::after{
                    display: none;
                }
            }
            .ant-steps-item-content{
                display: block;
            }
        }
        .ant-steps-vertical{
            margin: 20px 10%;

            .ant-steps-item{
                &:first-of-type,&:nth-of-type(2){
                    @media (max-width: 767px) {
                        height: 80px;
                    }
                }
                &:nth-of-type(3){
                    height: 140px;
                }
            }
            .ant-steps-item-process{
                .ant-steps-item-icon{
                    background: #E2E2E2;
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .ant-steps-icon{
                        line-height: 1;
                        width: auto;
                        height: auto;
                        font-size: inherit;
                    }
                    svg{
                        fill: #000;
                        width: 18px;
                        height: 18px;
                    }
               } 
               .ant-steps-item-tail{
                    &::after{
                        width: 2px;
                        background-color: #E2E2E2;
                    }
                }
            }
            .ant-steps-item-finish{
                .ant-steps-item-icon{
                    background: #40BA5B;
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .ant-steps-icon{
                        line-height: 1;
                        width: auto;
                        height: auto;
                        font-size: inherit;
                    }
                    svg{
                        fill: #fff;
                        width: 18px;
                        height: 18px;
                    }
               } 
               .ant-steps-item-tail{
                   &::after{
                       width: 2px;
                       background-color: #E2E2E2;
                   }
               }
            }
        }
        .pool{
            margin-bottom: 30px;
            img{
                height: 80px;
                margin: 0 auto;
                display: block;
            }
            p{
                margin: 0 auto;
                text-align: center;
                font-weight: bold;
                font-size: 28px;
                color: #000;
            }
        }
    }
    .no-miner{
        padding: 120px 0;
        img{
            width: 104px;
            margin: 0 auto 20px;
            display: block;
        }
        h2,p{
            text-align: center;
            margin-bottom: 10px;
        }
        a{
            color: #0FAE32;
            text-decoration: underline;
        }
    }
    .ant-radio-group{
        border: 1px solid #DEDEDE;
        border-radius: 20px;
        .ant-radio-button-wrapper{
            height: 38px;
            line-height: 38px;
            border-color: #DEDEDE!important;
            background: #EDEDED;
            border-radius: 20px!important;
            padding: 0 45px;
            border: none;
            &:hover{
                color: #000;
            }
            &-checked{
                background: #40BA5B;
                color: #fff;
                outline: none;
                &:not(.ant-radio-button-wrapper-disabled)::before{
                    display: none;
                }
                &:not(.ant-radio-button-wrapper-disabled):focus-within{
                    box-shadow: none;
                }
                &:hover{
                    color: #fff;
                }
            }
            &:not(:first-child)::before{
                display: none;
            }
            &:not(:first-of-type){
                margin-left: -34px;
            }
        }
    }
    .ant-table{
        border-radius: 6px;
        .ant-table-container table > thead > tr:first-child th:first-child{
            border-top-left-radius: 6px;
        }
        .ant-table-container table > thead > tr:first-child th:last-child{
            border-top-right-radius: 6px;
        }
    }
}
.withdrawModal{
    .attention{
        background: #F7F7F7;
        border-radius: 4px;
        padding: 20px;
        ol{
            padding-left: 15px;
        }
    }
    p{
        color: #9A9A9A;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 20px;
    }
    .adress{
        font-size: 14px;
        color: #000;
    }
    .amount{
        color: #000;
        font-size: 28px;
    }
    .btns{
        text-align: center;
        margin: 20px 0;
        .ant-btn{
            width: 160px;
            height: 40px;
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;