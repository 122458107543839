.discord-web3-status{
    margin-top: 28%;
    position: absolute;
    width: 520px;
    left: 50%;
    margin-left: -260px;
    .congratulation {
        text-align: center;
        margin-bottom: 20px;
        .title {
            width: 567px;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            margin-left: -20px;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: 7px;
        }
        .content {
            color: #fff;
            text-align: center;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 20px;
        }
    }

    .connect-btn{
        background: #000000;
        left: 50%;
        margin-left: -60px;
        height: 50px;
        line-height: 50px;
        color: #fff;
        display: flex;
        box-shadow: 0px 2px 0px #40BA5B;
        align-items: center!important;
        width: 150px;
        border: 1px solid #fff;
        &:hover{
            background: #292929;
        }
        svg{
            height: 20px!important;
        }
        @media (max-width: 767px) {
            margin: 0;
        }
        .span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            padding: 0px;
            /* or 171% */

            text-align: center;

            color: #FFFFFF;
        }
    }

    .account_btn {
            margin-left: -250px;
            left: 50%;
            box-sizing: border-box;
            width: 515px;
            height: 53px;
            background: #FFFFFF;
            border: 1px solid #000000;
            border-radius: 30px;
    }

    .verify-btn-only {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        left: 50%;
        margin-left: -125px;
        color: #fff;
        background: #000000;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 2px 0px #40BA5B;
        border-radius: 60px;
    }

    .verify-btn {
        width: 250px;
        height: 50px;
        margin-top: 30px;
        color: #fff;
        background: #000000;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 2px 0px #40BA5B;
        border-radius: 60px;
    }
    .verify-btn:last-child {
        margin-left: 20px;
    }
}

@primary-color: #000;@border-radius-base: 20px;