.discord {
    width: 100%;
    background: linear-gradient(180deg, #000000 0%, #3D407C 100%);
    min-height: 100vh;
    .container {
        width: 100%;
    }
    .logo {
        position: absolute;
        left: 50%;
        margin-left: -115px;
        margin-top: 20%;
    }
}

@primary-color: #000;@border-radius-base: 20px;