.mining-search{
    background-color: #fff;
    position: relative;
    max-width: 100%!important;
    border-bottom: 1px solid #DEDEDE;
    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 14px 35px!important;
        @media (max-width: 767px) {
            padding: 13px 30px!important;
        }
        box-shadow: none!important;
        position: relative;
        &>div{
            &:first-of-type{
                font-size: 20px;
                font-weight: bold;
                color: #000;
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
        }
        .ant-input-search{
            width: 400px;
            padding: 10px 0;
            .ant-input{
                border: none;
                border-radius: 0;
                font-size: 16px;
                &:focus{
                    box-shadow: none;
                }
            } 
            .ant-input-affix-wrapper{
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #000;
                &:focus, &:hover{
                    box-shadow: none;
                }
            }
            .ant-input-affix-wrapper-focused{
                box-shadow: none;
            }
            .ant-input-group-addon{
                left: -100%;
                top: 1px;
                .ant-input-search-button{
                    border: none;  
                    border-bottom: 1px solid #000;
                    border-radius: 0;
                }
            }
        }
    }
    .search-box{
        position: relative;
        right: -30px;
        @media (max-width: 767px) {
            right: 0;
        }
        .dropdown{
            position: absolute;
            top: 50px;
            left: -30px;
            background-color: #fff;
            width: 100%;
            padding:10px 20px 20px;
            z-index: 10;
            .item{
                background: #F4F4F4;
                border-radius: 30px;
                padding: 10px 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                cursor: pointer;
                margin-bottom: 10px;
                &>div{
                    &:first-of-type{
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &:last-of-type{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }
                svg{
                    fill: #B6B6B6;
                    font-size: 15px;
                    &:hover{
                        fill: #000;
                    }
                }
            }
            .dropheader{
                display: flex;
                flex-direction: row;
                justify-content: space-between; 
                margin-bottom: 10px;
                &>div{
                    &:first-of-type{
                        color: #9A9A9A;
                    }
                    &:last-of-type{
                       color: #000;
                       cursor: pointer;
                    }
                }
            }
        }
    }
    .dropdown{
        position: absolute;
        top:100%;
        z-index: 1000;
        background-color: #fff;
        width: 100%;
        padding:10px 20px 20px;
        z-index: 10;
        .item{
            background: #F4F4F4;
            border-radius: 30px;
            padding: 10px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;
            margin-bottom: 10px;
            &>div{
                &:first-of-type{
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &:last-of-type{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
            svg{
                fill: #B6B6B6;
                font-size: 15px;
                &:hover{
                    fill: #000;
                }
            }
        }
        .dropheader{
            display: flex;
            flex-direction: row;
            justify-content: space-between; 
            margin-bottom: 10px;
            &>div{
                &:first-of-type{
                    color: #9A9A9A;
                }
                &:last-of-type{
                   color: #000;
                   cursor: pointer;
                }
            }
        }
    }
}
.search-drawer{
    overflow: auto;
    .ant-drawer-content-wrapper{
        width: 100%!important;
    }
    .ant-drawer-body{
        padding: 10px 30px!important;
    }
    .search-box{
        position: relative;
        right: -30px;
        .ant-input-search{
            width: 100%;
            padding: 10px 0;
            .ant-input{
                border: none;
                border-bottom: 1px solid #000;
                border-radius: 0;
                font-size: 16px;
                &:focus{
                    box-shadow: none;
                }
            } 
            .ant-input-group-addon{
                position: relative;
                left: -100%!important;
                top: 1px;
                .ant-input-search-button{
                    border: none;  
                    border-bottom: 1px solid #000;
                    border-radius: 0!important;
                }
            }
        }
    }

}
@primary-color: #000;@border-radius-base: 20px;