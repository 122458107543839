.web3-status{
    .ant-btn{
        background: none;
        color: #fff;
        display: flex;
        align-items: center!important;
        margin: 0 10px;
        border: 1px solid #fff;
        &:hover{
            background: #292929;
        }
        svg{
            height: 20px!important;
        }
        @media (max-width: 767px) {
            margin: 0;
        }
    }
    &.light{
        .ant-btn{
            color: #000;
            border: 1px solid #000;
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;