@import "~antd/dist/antd.less";
a {
  color: #1673E1;
  &:hover {
    color: #1673E1;
    text-decoration: underline;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#mc_embed_signup{
  max-width: 1200px;
  text-align: right;
  margin: 0 auto;
  position: relative;
  bottom: 175px;
  right: 20px;
  @media (max-width: 767px) {
    bottom: 78px;
    right: 0;
  }
  form{
    width: 400px;
    float: right;
    padding: 10px 0!important;
    @media (max-width: 767px) {
      width: 100%;
      padding: 10px 30px!important;
    }
  }
  // width: 400px;
  // position: relative;
  // bottom: 140px;
  // right: 60px;
  // float: right;
  // max-width: 1200px;
  // margin: 0 auto;
  // @media (max-width: 767px) {
  //   width: 100%;
  //   right: 0;
  //   float: none;
  //   padding: 0 20px;
  // }
  .mc-field-group{
    width: 100%!important;
    input{
      height: 48px;
      border-radius: 24px;
      border: 2px solid #000000;
      font-size: 15px;
      line-height: 48px;
      padding: 0 20px!important;
    }
  }
  .optionalParent{
    position: absolute;
    right: 0;
    top: 14px;
    @media (max-width: 767px) {
      right: 30px;
    }
  }
  .button{
    padding: 0 30px;
    margin: 4px 0;
    width: 120px!important;
    height: 40px!important;
    border: none;
    border-radius: 48px!important;
    color: #fff;
    background: #00B828!important;
    font-size: 24px;
  }
  .foot{
    display: block!important;
  }
  #mce-responses{
    margin: 0!important;
  }
  .response{
    margin: 0!important;
  }
}

.ant-tooltip-inner{
  border-radius: 6px;
}
.ant-tooltip-arrow-content{
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0 0 5px 0;
}
.ant-dropdown{
  .ant-dropdown-menu{
    border-radius: 12px;
    .ant-dropdown-menu-item{
      border-radius: 12px;
    }
  }
  a{
    &:hover{
      text-decoration: none!important;
    }
  }
}
.ant-notification-notice{
  border-radius: 6px;
  border-left: 9px solid #40BA5B;
  .ant-notification-notice-message{
    font-weight: 700;
    font-size: 18px;
  }
  .ant-notification-notice-description{
    font-weight: 400;
    font-size: 16px;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  color: #fff;
}
.ant-select-dropdown{
  border-radius: 6px;
}
.ant-divider-vertical{
  border-left: 1px solid #D4D4D4;
  font-size: 20px;
}
.ant-table{
  border-radius: 0;
  .ant-table-container table > thead > tr:first-child th:first-child{
      border-top-left-radius: 0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child{
      border-top-right-radius: 0;
  }
  .ant-table-thead > tr > th{
      background-color: #000;
      color: #fff;
      padding: 5px 16px;
  }
  .ant-table-tbody > tr {
      &:nth-child(odd){
          background: #F9F9F9;
          td{
              &.ant-table-cell-fix-left{
                  background: #F9F9F9;
              }
          }
      }
      &.ant-table-row-selected > td{
        background: inherit;
      }
  }
}
@primary-color: #000;@border-radius-base: 20px;