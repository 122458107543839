.data-store{
    width: 100%;
    padding: 64px 0;
    background: #f9f9f9;
    min-height: 100vh;
    .learnmore{
        color: #000;
        margin-left: 6px;
    }
    .storage{
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        .ant-progress{
            width: 108px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            .ant-progress-outer{
                padding-right: 0;
                line-height: 1;
            }
            .ant-progress-text{
                display: none;
            }
        }
    }
    .loadmore{
        width: 300px;
        height: 20px;
        background: #EFEFEF;
        border-radius: 20px;
        line-height: 20px;
        font-size: 14px;
        border: none;
        padding: 0;
        margin: 15px auto;
        display: block;
        box-shadow: none;
    }
    .ant-tabs-content-holder{
        padding: 0 35px 25px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .ant-tabs{
        position: relative;
        overflow: auto;
        &::before{
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            height: 60px;
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #DEDEDE;
            @media (max-width: 767px) {
                height: 80px;
            }
        }
        .ant-tabs-nav{
            width: 100%;
            max-width: 1200px;
            margin: 0 auto 20px;
            height: 60px;
            padding: 0 35px!important;
            &::before{
                display: none;
            }
            @media (max-width: 767px) {
                padding: 0 30px!important;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
                height: 80px;
            }
            .ant-tabs-tab{
                font-size: 16px;
                font-weight: 400;
                color: #000;
                &-active{
                    font-weight: 700;
                }
            }
        }
        .ant-tabs-extra-content{
            @media (max-width: 767px) {
                padding: 10px 0;
            }
        }
    }
    .ant-tabs-content{
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }
    .account-detail-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background: #fff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .ant-btn-default{
            border-color: #000;
        }
        .ant-input{
            width: 200px;
        }
        .ant-select,.ant-input-affix-wrapper{
            width: 200px;
            margin-left: 20px;
            @media (max-width: 767px) {
                margin: 5px 0;
            }
        }
    }
    .ant-checkbox-inner{
        border-radius: 0;
    }
    .filename{
        display: flex;
        align-items: center;
        p{
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
            margin-bottom: 0;
        }
        svg{
            margin-right: 14px;
            width: 36px;
            height: auto;
            font-size: 20px;
        }
        .ant-btn{
            margin-left: 5px;
            svg{
                margin-right: 0px;
            }
        }
    }
}
.createAppModal{
    .ant-modal-body,.ant-modal-header{
        padding: 16px;
    }
    .ant-modal-close{
        color: #fff;
    }
    .ant-modal-header{
        background: #000;
        .ant-modal-title{
            color: #fff;
        }
    }
    .ant-radio-group{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        .ant-radio-button-wrapper{
            height: 100%;
            border: none;
            box-shadow: none;
            padding: 0;
            &::before{
                display: none;
            }
            .icon{
                border: 1px solid #E7E7E7;
                border-radius: 4px;
                padding: 37px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            h1, p{
                text-align: center;
            }
            h1{
                font-weight: 700;
                font-size: 14px;
                margin: 20px 0 0; 
                line-height: 1.1;
            }
            p{
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 0;
            }
            &-checked{
                border: none;
                box-shadow: none;
                &::before{
                    display: none;
                }
                .icon{
                    border: 1px solid #000;
                }
            }
        }
    }
    .ant-form-item-label{
        padding-bottom: 2px;
        & > label{
            display: block;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.3;
            margin-bottom: 10px;
        }
    }
    .ant-input{
        border: 1px solid #000;
        border-radius: 4px;
        height: 36px;
        line-height: 36px;
    }
    .ant-slider{
        .ant-slider-rail{
            height: 8px;
            background: #EFEFEF;
            border: 1px solid #D4D4D4;
        }
        .ant-slider-track{
            height: 8px;
            background-color: #40BA5B;
        }
        .ant-slider-step{
            height: 8px;
        }
        .ant-slider-handle{
            margin-top: -3px
        }
        .ant-slider-dot{
            display: none;
        }
    }
    .price{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-bottom: 20px;
        &>div{
            background: #F8F8F8;
            padding: 10px;
            &>div{
                font-size: 14px;
                font-weight: 400;
                &:last-of-type{
                    font-weight: 700;
                    span{
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
    .update{
        .ant-form-item-control-input-content{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 8px;
        }
    }
}
.fileInfoModal{
    .ant-modal-body,.ant-modal-header{
        padding: 16px;
    }
    .ant-modal-close{
        color: #fff;
    }
    .ant-modal-header{
        background: #000;
        .ant-modal-title{
            color: #fff;
        }
    }
    h2{
        font-weight: 700;
        font-size: 16px;
        color: #000;
    }
    .info{
        &>div{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &:last-of-type{
                margin-bottom: 0;
            }
            &>div{
                &:last-of-type{
                    width: 160px;
                    word-break: break-all;
                }
            }
        }
    }
    img{
        width: 100%;
    }
    .copyhash{
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .copyIcon{
        margin-left: 10px;
    }
}
.delete-modal{
    .ant-modal-confirm-title{
        font-weight: 700;
        font-size: 16px;
        color: #000;
        text-align: center;
    }
    .ant-modal-body{
        padding: 30px 18px;
    }
    .ant-modal-confirm-content{
        font-weight: 400;
        font-size: 14px;
        color: #000;
        text-align: center;
    }
    .ant-modal-confirm-btns{
        width: 100%;
        display: flex;
        .ant-btn{
            width: 50%;
            border-radius: 5px;
            height: 40px;
        }
        .ant-btn-default{
            background: #000;
            color: #fff;
        }
        .ant-btn-primary{
            background: #FF0000;
            border-color: #FF0000;
            color: #fff;
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;